<script setup>
import { useAuthStore } from "@/stores/auth.store";
import { useChatStore } from "@/stores/chat.store";
import { useUserStore } from "@/stores/user.store";
import { AutoComplete } from "ant-design-vue";
import { computed, ref, watch } from "vue";

const emit = defineEmits(["is:send", "is:typing"]);
const chatStore = useChatStore();
const userStore = useUserStore();
const authStore = useAuthStore();

const error = computed(() => chatStore.getError);
const errorTime = computed(() => chatStore.getErrorTime);
const showError = computed(() => error.value && errorTime.value > 0);
const commandList = ref(
  chatStore.getCommandList.map((item) => {
    return {
      text: item.content,
      value: item.shortcut,
    };
  }) || []
);
const filteredCommandList = ref([]);
const userType = computed(() => userStore.getUserType);

const input = ref();
const message = ref("");
const isDropdownVisible = ref(false);

let timer = null;
watch(
  () => showError.value,
  (value) => {
    if (value) {
      timer = setTimeout(() => {
        chatStore.setError(false);
        input.value.focus();
      }, errorTime.value * 1000);
    } else {
      clearTimeout(timer);
    }
  }
);

//chatStore.getCommandList watch
watch(
  () => chatStore.getCommandList,
  (value) => {
    commandList.value = value.map((item) => {
      return {
        text: item.content,
        value: item.shortcut,
      };
    });
  }
);

const addEmojiMessage = (event) => {
  message.value += event.detail.emoji.unicode;
};

const handleSendMsg = (e) => {
  if (!authStore.getIsChat) return;
  if (showError.value) return;
  if (message.value.length >= 3) {
    e.preventDefault();
    emit("is:send", message.value);
    message.value = null;
    isDropdownVisible.value = false;
    filteredCommandList.value = commandList.value;
  }
};

const handleSendMsgAutoComplete = (value, option) => {
  message.value = option.text;
};

const handleSearch = (value) => {
  if (value.length > 0) {
    isDropdownVisible.value = true;
  } else {
    isDropdownVisible.value = false;
    filteredCommandList.value = commandList.value;
  }

  filteredCommandList.value = commandList.value.filter((item) =>
    item.value.toLowerCase().includes(value.toLowerCase())
  );

  if (filteredCommandList.value.length === 0) {
    isDropdownVisible.value = false;
  }
};
</script>

<template>
  <span class="text-primary chat-error" v-if="showError">{{ error }}</span>
  <footer class="chat-detail-footer base-form">
    <div class="chat-detail-footer-left">
      <is-emoji-picker @is:click="addEmojiMessage"></is-emoji-picker>
    </div>
    <div class="chat-detail-footer-center">
      <a-textarea
        v-model:value="message"
        max-length="500"
        placeholder="Bir mesaj yazın"
        size="large"
        ref="input"
        :disabled="showError || !authStore.getIsChat"
        @keydown.enter="handleSendMsg"
        @change="$emit('is:typing')"
        v-if="Number(userType) !== 4"
      />
      <auto-complete
        v-model:value="message"
        :options="filteredCommandList"
        @select="handleSendMsgAutoComplete"
        :open="isDropdownVisible"
        @search="handleSearch"
        v-if="Number(userType) === 4"
        class="ant-input ant-input-lg input-bg"
      >
        <a-textarea
          max-length="500"
          placeholder="Bir mesaj yazın"
          size="large"
          ref="input"
          :disabled="showError || !authStore.getIsChat"
          @keydown.enter="handleSendMsg"
          @change="$emit('is:typing')"
        />
        <template #option="{ value: val }">
          <div class="autocomplete-options">
            <p>{{ val }}</p>
            <p>
              {{ commandList.find((item) => item.value === val).text }}
            </p>
          </div>
        </template>
      </auto-complete>
    </div>
    <div class="chat-detail-footer-right">
      <span class="chat-send-button" @click="handleSendMsg">
        <i class="bx bx-send font-size-150 icon-color"></i>
      </span>
    </div>
  </footer>
</template>

<style lang="scss">
.chat-error {
  font-size: 0.75rem;
  padding: 0.25rem 4rem;
}

.autocomplete-options {
  display: flex;
  justify-content: space-between;
  background-color: var(--theme-primary-dark-color);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--theme-primary-dark-color) !important;
}

.ant-select-dropdown {
  background-color: var(--theme-primary-dark-color) !important;
}

.autocomplete-options p {
  margin: 0;
  padding: 0.5rem;
  color: var(--theme-marquee-primary-text) !important;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input::placeholder {
  color: var(--theme-marquee-primary-text) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: var(--secondary-background-color) !important;
  border: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector::placeholder {
  color: var(--theme-marquee-primary-text) !important;
}

.autocomplete-options:hover {
  background-color: var(--dark-background-color) !important;
}

.ant-select-show-search
  .ant-select
  .ant-select-lg
  .ant-input
  .ant-input-lg
  .input-bg
  .ant-select-lg
  .ant-select-show-search
  .ant-select-auto-complete
  .ant-select-single {
  background-color: var(--default-background-color) !important;
  color: var(--text-color) !important;
  background: var(--default-background-color) !important;

  input {
    background-color: var(--default-background-color) !important;
    color: var(--text-color) !important;
    background: var(--default-background-color) !important;
  }
}

.chat-detail-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1rem;
  border-top: 1px solid var(--secondary-background-color);
  border-right: 1px solid var(--secondary-background-color);

  .chat-detail-footer-left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.5rem;
  }

  .chat-detail-footer-center {
    display: flex;
    flex-grow: 1;

    input {
      background-color: var(--default-background-color);
      color: var(--text-color);
      border: 1px solid var(--secondary-background-color);
      border-radius: 0.5rem;
      padding: 0.5rem;
      width: 100%;
    }

    div {
      input {
        background-color: var(--default-background-color);
        color: var(--text-color);
        border: 1px solid var(--secondary-background-color);
        border-radius: 0.5rem;
        padding: 0.5rem;
        width: 100%;
      }
    }
  }

  .chat-detail-footer-right {
    padding-left: 0.5rem;

    .chat-send-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
    }
  }
}
</style>
